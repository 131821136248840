import { css, flush, injectGlobal } from '@emotion/css';

import type { Theme } from '@Constants/themes';
import themes from '@Constants/themes';

export const getTheme = (): Theme => {
  return themes.default;
};

export const getCSSVariables = (
  theme: Theme | any,
  prefix = '-',
  results: Record<string, string> = {}
) => {
  if (typeof theme === 'string') {
    results[prefix] = theme;
  } else if (typeof theme === 'object') {
    for (const key in theme) {
      if (key === 'default') {
        getCSSVariables(theme[key], prefix ? `${prefix}` : key, results);
      } else {
        getCSSVariables(theme[key], prefix ? `${prefix}-${key}` : key, results);
      }
    }
  }

  return results;
};

export const setupTheme = () => {
  const theme = getCSSVariables(getTheme());

  const styles = Object.entries(theme)
    .map(([property, value]) => `${property}:${value};`)
    .join('');

  flush();
  injectGlobal(`:root { ${styles} }`);
};

/**
 * scopedCssVars creates a style-tag in the head of the page with css variables
 * scoped to a randomly generated className.
 *
 * @param variables - Only strings and numbers will be added to the style-tag.
 */
export const scopedCssVars = (variables: Record<string, any>) => {
  const cssVars = Object.entries(variables)
    .filter(([, value]) => ['string', 'number'].includes(typeof value))
    .map(([name, value]) => `--${name}:${value};`)
    .join('');

  if (!cssVars) return '';

  return css`
    ${cssVars}
  `;
};
