export const DEFAULT_LANGUAGE = 'nl';
export const IS_PRODUCTION = process.env.VERCEL_ENV === 'production';
export const IS_LOCAL =
  typeof process.env.REACT_APP_VERCEL_ENV === 'undefined' && process.env.NODE_ENV !== 'production'; // TODO: Fix for production
export const HTTP_CODE_UNAUTHORIZED = 401;

export const SENTRY_DSN = '';

export const PAGE_SIZE = 6;

export const isAdvisor = true; // TODO: implement isAdvisor
