import { Link } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';

export const AdvisorProjectsScreen = () => {
  return (
    <div>
      AdvisorProjectsScreen
      <Link to={AppRoutes.getAdvisorProjectDetail('projectid').to}>
        <br />
        Go to single project
      </Link>
    </div>
  );
};
