import { Outlet, useParams } from 'react-router-dom';

import { useIsCustomer } from '@Hooks/auth/useIsCustomer';
import { AppRoutes } from '@Navigation/Routes';
import { Redirect } from '@Utils/RouteUtils';

import { Page } from '../Page/Page';
import type { CustomerPageProps } from './CustomerPage.props';

export const CustomerPage = ({ children, ...props }: CustomerPageProps) => {
  const { customerId } = useParams();
  const isCustomer = useIsCustomer(customerId);

  const homeUrl = AppRoutes.getCustomerHome(customerId).to;

  const links = [
    AppRoutes.getCustomerHome(customerId),
    AppRoutes.getCustomerProjects(customerId),
    AppRoutes.getCustomerProducts(customerId)
  ];

  if (!isCustomer) {
    return <Redirect home="login" />;
  }

  return (
    <Page links={links} homeUrl={homeUrl} {...props}>
      <Outlet />

      {children}
    </Page>
  );
};
