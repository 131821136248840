import { useQuery } from 'react-query';

import { authService } from '@Services/AuthService';
import { getIsRefreshStillValid } from '@Utils/StorageUtils';

export const useIsAuthenticated = () => {
  const { data } = useQuery(
    [authService.getEndpoint(), 'me'],
    () => {
      return authService.getMe();
    },
    {
      initialData: getIsRefreshStillValid
    }
  );

  return !!data;
};
