import { useParams } from 'react-router-dom';

import { AppRoutes } from '@Navigation/Routes';

import { ProjectCard } from '../ProjectCard/ProjectCard';
import type { CustomerProjectCardProps } from './CustomerProjectCard.props';

export const CustomerProjectCard = (props: CustomerProjectCardProps) => {
  const { customerId } = useParams();
  return (
    <ProjectCard
      {...props}
      to={AppRoutes.getCustomerProjectDetail(customerId, props.project.id).to}
    />
  );
};
