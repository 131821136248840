import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Box, Spinner } from '@Components/ui';

import styles from './QueryIndicator.module.scss';
import type { QueryIndicatorProps } from './QueryIndicator.props';

export const QueryIndicator = ({
  className,
  children,
  isLoading,
  isError,
  modelName,
  ...props
}: QueryIndicatorProps) => {
  const classNames = cn(styles.QueryIndicator, className);
  const { t } = useTranslation();

  return (
    <div className={classNames} {...props}>
      {isLoading && (
        <Box my={6}>
          <Spinner />
        </Box>
      )}

      {isError && (
        <Box className={styles.Error} p={2}>
          {t('COMMON.GLOBALS.ERROR_FETCHING', {
            name: modelName
          })}
        </Box>
      )}

      {!isError && !isLoading && children}
    </div>
  );
};
