import { useQuery } from 'react-query';

import type { ICustomer, PublicCustomerRequest } from '@Services/Customer';
import { customerService } from '@Services/Customer';

export const useIsCustomer = (publicId: PublicCustomerRequest['publicId']) => {
  const { data, isPlaceholderData } = useQuery<ICustomer | true>(
    [customerService.getEndpoint(), 'public', publicId],
    () => {
      return customerService.getOnePublic({ publicId });
    },
    {
      placeholderData: true
    }
  );

  return !!data || isPlaceholderData;
};
