import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';

import { Icon } from '@Components/ui/Icon/Icon';
import { Tooltip } from '@Components/ui/Tooltip/Tooltip';

import styles from './InputField.module.scss';
import type { InputFieldProps } from './InputField.props';

export const InputField = ({
  className,
  label,
  children,
  name,
  description,
  ...props
}: InputFieldProps) => {
  const classNames = cn(styles.InputField, className);

  return (
    <div className={classNames} {...props}>
      <div className={styles.Header}>
        {!!label && (
          <label htmlFor={name} className={styles.Label}>
            {label}
          </label>
        )}

        {!!description && (
          <Tooltip description={description}>
            {<Icon hasCircle size={12} icon={faInfoCircle} />}
          </Tooltip>
        )}
      </div>

      {children}
    </div>
  );
};
