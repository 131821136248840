import { useParams } from 'react-router-dom';

import { Link } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';

export const CustomerProjectsScreen = () => {
  const { customerId } = useParams();
  return (
    <div>
      CustomerProjectsScreen
      <Link to={AppRoutes.getCustomerProjectDetail(customerId, 'projectid').to}>
        <br />
        Go to single project
      </Link>
    </div>
  );
};
