import { CustomerProjectsPreview } from '@Components/network';

import styles from './CustomerHomeScreen.module.scss';

export const CustomerHomeScreen = () => {
  return (
    <div className={styles.CustomerHomeScreen}>
      <CustomerProjectsPreview className={styles.Projects} />
    </div>
  );
};
