import INPUT_FILE from './INPUT_FILE.json';
import INPUT_SELECT from './INPUT_SELECT.json';
import PAGE from './PAGE.json';
import PROJECT_CARD from './PROJECTCARD.json';

export default {
  PAGE,
  PROJECT_CARD,
  INPUT_FILE,
  INPUT_SELECT
};
