import '@fontsource/quicksand';

import type { Themes } from './themes.props';

const themes = {
  default: {
    spacing: 8,

    color: {
      primary: {
        default: '#008cd1',
        light: '#00acd2',
        lighter: '#f2f9fC'
      },
      secondary: {
        default: '#ffa500',
        light: '#ffd78c'
      },
      white: {
        light: '#ffffff14',
        default: '#fff',
        darker: '#f8f8f7',
        shadow: '#f2f2f2'
      },
      black: {
        default: '#000',
        overlay: 'rgba(0, 0, 0, 0.3)'
      },
      gray: {
        default: '#ccc',
        dove: '#666',
        light: '#efefef',
        dark: '#252525'
      },
      progress: {
        default: '#37c15e'
      },
      placeholder: {
        default: '#aeaeae'
      },
      red: {
        default: '#d51616',
        light: '#ffe6e6'
      }
    },

    shadow: {
      medium: '0 2px 16px 0 var(--color-white-shadow)',
      large: '7px 2px 70px 0 var(--color-black-overlay)'
    },

    font: {
      default: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '1.5',
        fontFamily: 'Quicksand'
      },

      input: {
        lineHeight: '1.8'
      },

      label: {
        lineHeight: '14px',
        fontWeight: '700',
        fontSize: '12px'
      },

      sm: {
        fontSize: '12px'
      },

      md: {
        fontSize: '1rem'
      },

      bold: {
        fontWeight: '700'
      },

      table: {
        fontSize: '12px',
        fontWeight: '700'
      },

      dropdown: {
        lineHeight: '1'
      },

      h1: {
        fontWeight: '700',
        fontSize: '3rem',
        lineHeight: 'calc(4/3)'
      },

      h2: {
        fontWeight: '700',
        fontSize: '28px',
        lineHeight: '1.14'
      },

      h3: {
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: 'calc(4/3)'
      },

      h4: {
        fontWeight: '700',
        fontSize: '1rem',
        lineHeight: '1.19'
      },

      h5: {
        fontSize: '12px',
        lineHeight: 'calc(4/3)'
      },

      normal: {
        lineHeight: '1.78'
      },

      small: {
        fontSize: '11px',
        lineHeight: '1.33'
      },

      quote: {
        fontSize: '20px',
        fontWeight: '700'
      }
    },

    radius: {
      input: '5px',
      xs: '6px',
      button: '8px',
      md: '12px',
      round: '9999px'
    },

    easing: {
      out: 'cubic-bezier(0.2, 0.4, 0, 1)',
      in: 'cubic-bezier(1, 0, 0.8, 0.6)',
      inOut: 'cubic-bezier(0.66, 0.07, 0.33, 0.94)'
    },

    gradient: {
      placeholder: 'linear-gradient(0.6turn, var(--color-primary-light), var(--color-primary))',
      fade: 'linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))'
    },

    timings: {
      xs: '100ms',
      sm: '200ms',
      md: '250ms',
      lg: '300ms',
      xl: '400ms',
      xxl: '500ms'
    }
  }
} as Themes;

export * from './themes.props';

export default themes;
