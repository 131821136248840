import * as Yup from 'yup';

import { PASSWORD, PHONE } from '@Constants/regexp';
import { i18n } from '@Utils/TranslationUtils';

export const setupYupTranslations = () => {
  Yup.setLocale(i18n.t('COMMON.YUP', { returnObjects: true }));
};

export const setupYup = () => {
  setupYupTranslations();
};

export interface AsyncValidationOptions {
  ignore?: string | string[];
  schema?: Yup.AnySchema;
}

export interface AsyncValidationCall {
  isValid: boolean;
  reason?: string;
}

export const getAsyncValidation = (
  call: (value: string) => Promise<AsyncValidationCall>,
  options: AsyncValidationOptions
) => {
  return Yup.string().test({
    name: 'async',
    test: async function (value: any) {
      const { path, createError } = this;

      // Validate custom schema
      if (options.schema) {
        try {
          await options.schema.validate(value);
        } catch ({ message }) {
          return createError({
            path,
            message: String(message)
          });
        }
      }

      // Validate with async call
      const response = await call(value);

      if (response.isValid) {
        return true;
      }

      const message = response.reason
        ? i18n.t(`DOMAIN.YUP.string.async.${path}.${response.reason.toLowerCase()}` as any)
        : i18n.t(`DOMAIN.YUP.string.async.${path}` as any);

      return createError({
        path,
        message: message
      });
    }
  });
};

Yup.addMethod(Yup.string, 'phone', () => {
  return Yup.string().test('phone', '', function (value: any) {
    const { path, createError } = this;

    return (
      (value && value.match(PHONE)) ||
      createError({ path, message: i18n.t('DOMAIN.YUP.string.phone') })
    );
  });
});

Yup.addMethod(Yup.string, 'password', (ref: string) => {
  // Check if password is equal to the reference
  if (ref) {
    return Yup.string().test('password', '', function (value: any) {
      const { path, createError } = this;

      return (
        this.parent[ref] === value ||
        createError({
          path,
          message: i18n.t('DOMAIN.YUP.string.password_match')
        })
      );
    });
  }

  // Check if password is in correct format
  return Yup.string().test('password', '', function (value: any) {
    const { path, createError } = this;

    return (
      (value && value.match(PASSWORD)) ||
      createError({ path, message: i18n.t('DOMAIN.YUP.string.password') })
    );
  });
});

export default Yup;
