import { faSignOut } from '@fortawesome/pro-solid-svg-icons';
import { useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAuthGetMe, useAuthLogout } from '@Hooks/auth';
import { AppRoutes } from '@Navigation/Routes';

import { Page } from '../Page/Page';
import type { AdvisorPageProps } from './AdvisorPage.props';

export const AdvisorPage = ({ children, ...props }: AdvisorPageProps) => {
  const authLogout = useAuthLogout();
  const navigate = useNavigate();
  const { data } = useAuthGetMe();

  const handleSignoutClick = useCallback(async () => {
    await authLogout.mutateAsync();
    navigate(AppRoutes.getLogin().to);
  }, [authLogout, navigate]);

  const homeUrl = AppRoutes.getAdvisorHome().to;

  const links = [
    AppRoutes.getAdvisorHome(),
    AppRoutes.getAdvisorProjects(),
    AppRoutes.getAdvisorProducts()
  ];

  const icons = [
    {
      icon: faSignOut,
      onClick: handleSignoutClick
    }
  ];

  return (
    <Page icons={icons} homeUrl={homeUrl} links={links} {...props}>
      <Outlet context={data} />

      {children}
    </Page>
  );
};
