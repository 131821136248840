import cn from 'classnames';
import { capitalize } from 'lodash';

import { Link } from '@Components/ui';

import { Icon } from '../Icon/Icon';
import styles from './Anchor.module.scss';
import type { AnchorProps } from './Anchor.props';

export const Anchor = ({
  className,
  hasUnderline = true,
  children,
  icon,
  color,
  ...props
}: AnchorProps) => {
  const colorName = capitalize(color);

  const classNames = cn(styles.Anchor, className, {
    [styles.hasUnderline]: hasUnderline,
    [styles[`is${colorName}`]]: Boolean(color)
  });

  return (
    <Link className={classNames} {...props}>
      {children}

      {icon && <Icon className={styles.Icon} icon={icon} />}
    </Link>
  );
};
