import COMMON from './common';
import COMPONENTS from './components';
import DOMAIN from './domain';

export default {
  translation: {
    COMPONENTS,
    COMMON,
    DOMAIN
  }
};
