import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CustomerProjectCard } from '@Components/container';
import { PreviewWrapper } from '@Components/layout';
import { QueryIndicator } from '@Components/ui';
import { useCustomerGetProjects } from '@Hooks/Customer';
import { AppRoutes } from '@Navigation/Routes';

import type { CustomerProjectsPreviewProps } from './CustomerProjectsPreview.props';

export const CustomerProjectsPreview = (props: CustomerProjectsPreviewProps) => {
  const { t } = useTranslation();
  const { customerId } = useParams();

  const { data: customerProjects, ...query } = useCustomerGetProjects({ perPage: 3 });

  return (
    <QueryIndicator {...query} modelName={t('DOMAIN.PROJECT.PLURAL')} {...props}>
      {customerProjects && (
        <PreviewWrapper
          title={t('DOMAIN.CUSTOMER.HOME_PAGE.PROJECTS.TITLE')}
          buttonLabel={t('DOMAIN.CUSTOMER.HOME_PAGE.PROJECTS.BUTTON_LABEL')}
          buttonTo={AppRoutes.getCustomerProjects(customerId).to}
        >
          {customerProjects.results?.map((project) => {
            return <CustomerProjectCard project={project} key={project.id} />;
          })}
        </PreviewWrapper>
      )}
    </QueryIndicator>
  );
};
