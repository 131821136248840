import { useCallback } from 'react';
import { Container } from 'react-bootstrap';

import { Button, Text } from '@Components/ui';
import { useAuthLogin } from '@Hooks/auth';

export const AuthLoginScreen = () => {
  const authLogin = useAuthLogin();

  const handleSubmit = useCallback(async () => {
    await authLogin.mutateAsync({
      email: 'backend+advisor@socialbrothers.nl', //TODO: implement Form when ready and use forms values to login
      password: 'Test1234!'
    });
  }, [authLogin]);

  return (
    <Container>
      <Text.H1>AuthLoginScreen</Text.H1>

      <Button onClick={handleSubmit}>Login</Button>
    </Container>
  );
};
