import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import { intlFormat, isValid, parseJSON } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Anchor, Card, Icon, Text } from '@Components/ui';

import styles from './ProjectCard.module.scss';
import type { ProjectCardProps } from './ProjectCard.props';

export const ProjectCard = ({ className, project, ...props }: ProjectCardProps) => {
  const classNames = cn(styles.ProjectCard, className);

  const { t, i18n } = useTranslation();

  const { category, name, createdAt } = project;

  const date = parseJSON(createdAt);
  const formattedDate = isValid(date)
    ? intlFormat(
        date,
        {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        },
        {
          locale: i18n.language
        }
      )
    : null;

  return (
    <Card className={classNames} {...props}>
      <Card.Image src={category?.image} ratio={350 / 200} />

      <Card.Main className={styles.Main}>
        <Text.H1 color="primary" className={styles.Title}>
          {name}
        </Text.H1>

        {!!formattedDate && (
          <time dateTime={createdAt} className={styles.Date}>
            <Icon icon={faCalendar} size={16} color="primary" className={styles.Icon} />

            <Text.Normal>{formattedDate}</Text.Normal>
          </time>
        )}

        <footer className={styles.Footer}>
          <Anchor
            icon={faArrowRight}
            color="secondary"
            hasUnderline={false}
            className={styles.Button}
          >
            {t('COMPONENTS.PROJECT_CARD.BUTTON')}
          </Anchor>
        </footer>
      </Card.Main>
    </Card>
  );
};
