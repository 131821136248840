import cn from 'classnames';

import styles from './InputTextArea.module.scss';
import type { InputTextAreaProps } from './InputTextArea.props';

export const InputTextArea = ({
  className,
  children,
  label,
  name,
  description,
  isError,
  ...props
}: InputTextAreaProps) => {
  const classNames = cn(styles.InputTextArea, className, {
    [styles.isError]: isError
  });

  return <textarea className={classNames} name={name} id={name} {...props} />;
};
