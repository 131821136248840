import { faSort, faSortAsc, faSortDesc } from '@fortawesome/pro-solid-svg-icons';

import { Icon } from '@Components/ui';
import { SortDirection } from '@Constants/enums';

import styles from './SortButton.module.scss';
import type { SortButtonProps } from './SortButton.props';

export const SortButton = ({ direction }: SortButtonProps) => {
  let icon = faSort;

  if (direction === SortDirection.DESC) {
    icon = faSortDesc;
  }

  if (direction === SortDirection.ASC) {
    icon = faSortAsc;
  }

  return <Icon className={styles.SortButton} icon={icon} />;
};
