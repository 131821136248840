import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import * as Checkbox from '@radix-ui/react-checkbox';
import cn from 'classnames';
import { useCallback } from 'react';

import { Icon, Text } from '@Components/ui';

import styles from './InputCheckbox.module.scss';
import type { InputCheckboxProps } from './InputCheckbox.props';

export const InputCheckbox = ({
  className,
  isError,
  label,
  id,
  value = [],
  onChange,
  options,
  ...props
}: InputCheckboxProps) => {
  const classNames = cn(styles.InputCheckbox, className, {
    [styles.isError]: isError
  });

  const handleChange = useCallback(
    (name: string | number) => (isChecked: boolean) => {
      let newValue: Array<string | number> = [];

      if (isChecked && !value.includes(name)) {
        newValue = [...value, name];
      }

      if (!isChecked && value.includes(name)) {
        newValue = value.filter((item) => {
          return item !== name;
        });
      }

      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange, value]
  );

  return (
    <div className={classNames} {...props}>
      {options.map((option) => {
        return (
          <div className={styles.Option} key={option.value}>
            <Checkbox.Root
              id={`${id}-${option.value}`}
              data-testid={props['data-testid'] && `${props['data-testid']}-${option.value}`}
              className={styles.Checkbox}
              checked={value.includes(option.value)}
              onCheckedChange={handleChange(option.value)}
            >
              <Checkbox.Indicator>
                <Icon icon={faCheck} />
              </Checkbox.Indicator>
            </Checkbox.Root>

            <Text.Normal as="label" htmlFor={`${id}-${option.value}`} className={styles.Label}>
              {label}
            </Text.Normal>
          </div>
        );
      })}
    </div>
  );
};
