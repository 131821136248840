import { useParams } from 'react-router-dom';

import { AppError } from '@Constants/enums';
import type { ListRequest } from '@Constants/interfaces';
import { useProjectGetListForCustomer } from '@Hooks/Project';

export const useCustomerGetProjects = (config?: ListRequest) => {
  const { customerId } = useParams();

  if (!customerId) {
    throw new Error(AppError.NO_CUSTOMER_ID_PARAM);
  }

  return useProjectGetListForCustomer({ customerId, ...config });
};
