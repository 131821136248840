import { Route, Routes } from 'react-router-dom';

import { AdvisorPage } from '@Components/layout';
import {
  AdvisorHomeScreen,
  AdvisorProductDetailScreen,
  AdvisorProductsScreen,
  AdvisorProjectDetailScreen,
  AdvisorProjectsScreen
} from '@Screens/Advisor';
import { Redirect } from '@Utils/RouteUtils';

import { AppRoutes } from './Routes';

export const AdvisorStack = () => {
  return (
    <Routes>
      <Route path={AppRoutes.getAdvisorHome().path} element={<AdvisorPage />}>
        <Route index element={<AdvisorHomeScreen />} />

        <Route path={AppRoutes.getAdvisorProjects().path}>
          <Route index element={<AdvisorProjectsScreen />} />

          <Route
            path={AppRoutes.getAdvisorProjectDetail().path}
            element={<AdvisorProjectDetailScreen />}
          />
        </Route>

        <Route path={AppRoutes.getAdvisorProducts().path} element={<AdvisorProductsScreen />}>
          <Route
            path={AppRoutes.getAdvisorProductDetail().path}
            element={<AdvisorProductDetailScreen />}
          />
        </Route>
      </Route>

      <Route path="*" element={<Redirect home="advisor" />} />
    </Routes>
  );
};
