import { acousticClient } from '@Services/clients';
import { ResourceService } from '@Services/common';

import type {
  CustomerCreateRequest,
  CustomerUpdateRequest,
  ICustomer,
  PublicCustomerRequest
} from './CustomerService.props';

export class CustomerService extends ResourceService<ICustomer> {
  getOnePublic = async ({ publicId }: PublicCustomerRequest) => {
    const { data } = await this.get<ICustomer>(`/${publicId}/public`);

    return data;
  };

  createCustomer = async (params: CustomerCreateRequest) => {
    const response = await this.post<ICustomer>(`/`, params);

    return response.data;
  };

  updateCustomer = async (params: CustomerUpdateRequest) => {
    const response = await this.put<ICustomer>(`/`, params);

    return response.data;
  };
}

export const customerService = new CustomerService(acousticClient.getAxiosInstance(), '/customers');
