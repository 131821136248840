import cn from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Footer, Navbar } from '@Components/layout';
import { Text } from '@Components/ui';

import styles from './Page.module.scss';
import type { PageProps } from './Page.props';

export const Page = ({
  className,
  children,
  links,
  icons,
  homeUrl,
  logoSrc,
  ...props
}: PageProps) => {
  const { t } = useTranslation();

  const classNames = cn(styles.Page, className);

  return (
    <div className={classNames} {...props}>
      <Navbar links={links} icons={icons} homeUrl={homeUrl} logoSrc={logoSrc} />

      <main>
        <Container>
          <Row>
            <Col xs={12}>{children}</Col>
          </Row>
        </Container>
      </main>

      <Footer className={styles.Footer} title={t('COMPONENTS.PAGE.FOOTER.TITLE')}>
        <Text.Small>{t('COMPONENTS.PAGE.FOOTER.SUBTITLE')}</Text.Small>
      </Footer>
    </div>
  );
};
