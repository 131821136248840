import cn from 'classnames';
import { get } from 'lodash';

import styles from './Row.module.scss';
import type { RowProps } from './Row.props';

export const Row = ({ columns, row, className, ...props }: RowProps) => {
  const classNames = cn(styles.Row, className);

  return (
    <tr className={classNames} {...props}>
      {columns.map((column) => (
        <td className={styles.Cell} key={column}>
          {get(row, column)}
        </td>
      ))}
    </tr>
  );
};
