import * as RadixTooltip from '@radix-ui/react-tooltip';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import '@Assets/styles';

import { App } from '@Src/App';
import { setupSentry } from '@Utils/SentryUtils';
import { setupTheme } from '@Utils/ThemeUtils';
import { setupTranslation } from '@Utils/TranslationUtils';
import { setupYup } from '@Utils/YupUtils';

setupSentry();
setupTheme();
setupTranslation();
setupYup();

const client = new QueryClient();

render(
  <StrictMode>
    <QueryClientProvider client={client}>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}

      <RadixTooltip.Provider delayDuration={0}>
        <App />
      </RadixTooltip.Provider>
    </QueryClientProvider>
  </StrictMode>,
  document.getElementById('root')
);
