import type { AxiosInstance, AxiosRequestConfig } from 'axios';

import { toQueryString } from '@Utils/StringUtils';

type Endpoint = `/${Lowercase<string>}`;

export class BaseService {
  constructor(private axios: AxiosInstance, protected endpoint: Endpoint) {}

  public getAxiosInstance = () => {
    return this.axios;
  };

  public getEndpoint = () => {
    return this.endpoint;
  };

  protected get = async <T>(url?: Endpoint, data?: any, config?: AxiosRequestConfig) => {
    return this.axios.get<T>(`${this.endpoint}${url ?? ''}?${toQueryString(data)}`, config);
  };

  protected delete = <T>(url: Endpoint, data?: any, config?: AxiosRequestConfig) => {
    const args = {
      ...config,
      params: {
        ...(config?.params || {}),
        data
      }
    };

    return this.axios.delete<T>(`${this.endpoint}${url}`, args);
  };

  protected post = <T>(url: Endpoint, data?: any, config?: AxiosRequestConfig) => {
    return this.axios.post<T>(`${this.endpoint}${url}`, data || {}, config);
  };

  protected put = <T>(url: Endpoint, data?: any, config?: AxiosRequestConfig) => {
    return this.axios.put<T>(`${this.endpoint}${url}`, data || {}, config);
  };
}
