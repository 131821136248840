import cn from 'classnames';
import { Box } from 'react-polymorphic-box';

import { scopedCssVars } from '@Utils/ThemeUtils';

import { Error, H1, H2, H3, H4, H5, Normal, Small } from './internal/components';
import styles from './Text.module.scss';
import type { TextElement, TextProps } from './Text.props';

const defaultElement = 'p' as TextElement;
export const BaseText = <T extends TextElement = typeof defaultElement>({
  className,
  color,
  dangerousHtml,
  as = defaultElement as T,
  ...props
}: TextProps<T>) => {
  const textVars = scopedCssVars({
    textColor: !!color && `var(--color-${color})`
  });

  const atts = {
    as,
    className: cn(textVars, styles.Text, className),
    dangerouslySetInnerHTML: !!dangerousHtml ? { __html: dangerousHtml } : undefined,
    ...props
  } as TextProps<T>;

  return <Box {...atts} />;
};

export const Text = {
  H1,
  H2,
  H3,
  H4,
  H5,

  Error,
  Normal,
  Small
};
