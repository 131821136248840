import type { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { HTTP_CODE_UNAUTHORIZED } from '@Constants/configs';

import type { LogoutHandler, RefreshHandler, Subscriber, TokenExtractor } from './ApiClient.props';

export class ApiClient {
  private axios: AxiosInstance;

  private subscribers: Subscriber[] = [];

  private isAlreadyFetchingAccessToken = false;

  constructor(
    baseURL: string,
    private logout: LogoutHandler,
    private refresh: RefreshHandler,
    private tokenExtractor: TokenExtractor
  ) {
    this.axios = axios.create({ baseURL });

    this.setupInterceptors();
  }

  setupInterceptors = () => {
    this.setupAccessTokenInterceptor();
    this.setupRefreshInterceptor();
  };

  setupAccessTokenInterceptor = () => {
    this.axios.interceptors.request.use(async (config) => {
      const token = this.tokenExtractor();

      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
      };

      return config;
    });
  };

  setupRefreshInterceptor = () => {
    this.axios.interceptors.response.use(
      (response) => response,

      async (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (response && response.status === HTTP_CODE_UNAUTHORIZED) {
          return this.refreshAndRetry(originalRequest, error);
        }

        return Promise.reject(error);
      }
    );
  };

  onRefreshSuccess = () => {
    this.subscribers.filter((subscriber) => subscriber.onSuccess());
  };

  onRefreshFailed = () => {
    this.subscribers.filter((subscriber) => subscriber.onFailure());
  };

  refreshAndRetry = async (originalRequest: AxiosRequestConfig, error: AxiosError) => {
    const retry = new Promise((resolve, reject) => {
      this.subscribers.push({
        onSuccess: () => {
          return resolve(this.axios(originalRequest));
        },
        onFailure: () => {
          return reject(error);
        }
      });
    });

    if (!this.isAlreadyFetchingAccessToken) {
      this.isAlreadyFetchingAccessToken = true;

      try {
        await this.refresh();
        this.onRefreshSuccess();
      } catch (e) {
        await this.logout();
        this.onRefreshFailed();
      } finally {
        this.isAlreadyFetchingAccessToken = false;
      }
    }

    return retry;
  };

  getAxiosInstance = () => {
    return this.axios;
  };
}
