import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { QueryIndicator } from '@Components/ui';
import { useTable } from '@Hooks/common/useTable';
import { useCustomerGetList } from '@Hooks/customers';
import type { ICustomer } from '@Services/Customer';

import type { AdvisorCustomersProps } from './AdvisorCustomers.props';

export const AdvisorCustomers = ({ ...props }: AdvisorCustomersProps) => {
  const { t } = useTranslation();

  const { Table, sortBy, sortDirection } = useTable<ICustomer>();

  const { data, isLoading, isError } = useCustomerGetList({
    sortBy,
    sortDirection,
    perPage: 9999
  });

  const customers = useMemo(() => {
    return (data?.pages ?? []).flatMap(({ results }) => results ?? []);
  }, [data]);

  return (
    <QueryIndicator
      isLoading={isLoading}
      isError={isError}
      modelName={t('DOMAIN.CUSTOMER.PLURAL')}
      {...props}
    >
      <Table data={customers}>
        <Table.Column source="name">Klant</Table.Column>

        <Table.Column source="number">Klantnummer</Table.Column>

        <Table.Column source="accountManager.firstName">Accountmanager</Table.Column>

        <Table.Column source="createdAt">Aangemaakt op</Table.Column>
      </Table>
    </QueryIndicator>
  );
};
