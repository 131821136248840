import type { To } from 'react-router-dom';

import locales from '@Locales/index';
import type { NavSlugs, TranslatedSlugs } from '@Utils/RouteUtils';
import { getLink, getLinkLabel } from '@Utils/RouteUtils';

export const Slugs = Object.fromEntries(
  Object.keys(locales.nl.translation.COMMON.NAVIGATION).map((key) => [key, key])
) as Record<TranslatedSlugs, TranslatedSlugs>;

export interface RouteType {
  path: string;
  label: string;
  to: To;
}

export type RouteMethod = (...args: NavSlugs[]) => RouteType;

type AppRoutesType = {
  [K in keyof AppRoutesBase]: K extends `get${Capitalize<string>}`
    ? K extends Lowercase<K>
      ? never
      : RouteMethod
    : never;
};

class AppRoutesBase implements AppRoutesType {
  getAdvisorHome = () => ({
    path: getLink(Slugs.ADVISOR_HOME),
    label: getLinkLabel(Slugs.ADVISOR_HOME),
    to: getLink(Slugs.ADVISOR_HOME)
  });

  getCustomerHome = (customerId?: string) => ({
    path: getLink(Slugs.CUSTOMER_HOME),
    label: getLinkLabel(Slugs.CUSTOMER_HOME),
    to: getLink(customerId)
  });

  getAdvisorProjects = () => {
    const { path, to } = this.getAdvisorHome();
    return {
      path: getLink(path, Slugs.PROJECTS),
      label: getLinkLabel(Slugs.PROJECTS),
      to: getLink(to, Slugs.PROJECTS)
    };
  };

  getCustomerProjects = (customerId?: string) => {
    const { path, to } = this.getCustomerHome(customerId);
    return {
      path: getLink(path, Slugs.PROJECTS),
      label: getLinkLabel(Slugs.PROJECTS),
      to: getLink(to, Slugs.PROJECTS)
    };
  };

  getCustomerProjectDetail = (customerId?: string, projectId?: string) => {
    const { path, to } = this.getCustomerProjects(customerId);
    return {
      path: getLink(path, Slugs.PROJECTS_DETAIL),
      label: getLinkLabel(Slugs.PROJECTS_DETAIL),
      to: getLink(to, projectId)
    };
  };

  getAdvisorProjectDetail = (projectId?: string) => {
    const { path, to } = this.getAdvisorProjects();
    return {
      path: getLink(path, Slugs.PROJECTS_DETAIL),
      label: getLinkLabel(Slugs.PROJECTS_DETAIL),
      to: getLink(to, projectId)
    };
  };

  getCustomerProducts = (customerId?: string) => {
    const { path, to } = this.getCustomerHome(customerId);
    return {
      path: getLink(path, Slugs.PRODUCTS),
      label: getLinkLabel(Slugs.PRODUCTS),
      to: getLink(to, Slugs.PRODUCTS)
    };
  };

  getAdvisorProducts = () => {
    const { path, to } = this.getAdvisorHome();
    return {
      path: getLink(path, Slugs.PRODUCTS),
      label: getLinkLabel(Slugs.PRODUCTS),
      to: getLink(to, Slugs.PRODUCTS)
    };
  };

  getCustomerProductDetail = (customerId?: string, productId?: string) => {
    const { path, to } = this.getCustomerProducts(customerId);
    return {
      path: getLink(path, Slugs.PRODUCTS_DETAIL),
      label: getLinkLabel(Slugs.PRODUCTS_DETAIL),
      to: getLink(to, productId)
    };
  };

  getAdvisorProductDetail = (productId?: string) => {
    const { path, to } = this.getAdvisorProducts();
    return {
      path: getLink(path, Slugs.PRODUCTS_DETAIL),
      label: getLinkLabel(Slugs.PRODUCTS_DETAIL),
      to: getLink(to, productId)
    };
  };

  getCustomerAccount = (customerId?: string) => {
    const { path, to } = this.getCustomerHome(customerId);
    return {
      path: getLink(path, Slugs.ACCOUNT),
      label: getLinkLabel(Slugs.ACCOUNT),
      to: getLink(to, Slugs.ACCOUNT)
    };
  };

  getAdvisorAccount = () => {
    const { path, to } = this.getAdvisorHome();
    return {
      path: getLink(path, Slugs.ACCOUNT),
      label: getLinkLabel(Slugs.ACCOUNT),
      to: getLink(to, Slugs.ACCOUNT)
    };
  };

  getLogin = () => ({
    path: getLink(Slugs.LOGIN),
    label: getLinkLabel(Slugs.LOGIN),
    to: getLink(Slugs.LOGIN)
  });

  getForgotPassword = () => ({
    path: getLink(Slugs.FORGOT_PASSWORD),
    label: getLinkLabel(Slugs.FORGOT_PASSWORD),
    to: getLink(Slugs.FORGOT_PASSWORD)
  });

  getUpdatePassword = () => ({
    path: getLink(Slugs.EDIT_PASSWORD),
    label: getLinkLabel(Slugs.EDIT_PASSWORD),
    to: getLink(Slugs.EDIT_PASSWORD)
  });

  getUpdatePasswordComplete = () => ({
    path: getLink(Slugs.EDIT_PASSWORD_COMPLETE),
    label: getLinkLabel(Slugs.EDIT_PASSWORD_COMPLETE),
    to: getLink(Slugs.ADVISOR_HOME, Slugs.EDIT_PASSWORD_COMPLETE)
  });
}

export const AppRoutes = new AppRoutesBase();
