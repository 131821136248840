import cn from 'classnames';

import { Link } from '@Components/ui/Link/Link';
import { capitalize } from '@Utils/StringUtils';

import styles from './Button.module.scss';
import type { ButtonProps } from './Button.props';

export const Button = ({
  className,
  children,
  isOutline,
  color = 'secondary',
  ...props
}: ButtonProps) => {
  const colorName = capitalize(color);

  const classNames = cn(styles.Button, className, {
    [styles.isOutline]: isOutline,
    [styles[`is${colorName}`]]: Boolean(color)
  });

  return (
    <Link className={classNames} {...props}>
      {children}
    </Link>
  );
};
