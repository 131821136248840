import { useQuery } from 'react-query';

import type { ProjectGetListForCustomerRequest } from '@Services/Project';
import { projectService } from '@Services/Project';

export const useProjectGetListForCustomer = (request: ProjectGetListForCustomerRequest) => {
  return useQuery([projectService.getEndpoint(), 'customer', request], () => {
    return projectService.getListForCustomer(request);
  });
};
