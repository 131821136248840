import { Link } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';

export const AdvisorProjectDetailScreen = () => {
  return (
    <div>
      AdvisorProjectDetailScreen
      <Link to={AppRoutes.getAdvisorProjects().to}>
        <br />
        Back to Projects
      </Link>
    </div>
  );
};
