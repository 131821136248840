import { BrowserRouter } from 'react-router-dom';

import { useIsAuthenticated } from '@Hooks/auth/useIsAuthenticated';

import { AdvisorStack } from './AdvisorStack';
import { PublicStack } from './PublicStack';

export const Router = () => {
  const isAuthenticated = useIsAuthenticated();

  return <BrowserRouter>{isAuthenticated ? <AdvisorStack /> : <PublicStack />}</BrowserRouter>;
};
