import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';

import { Icon } from '../Icon/Icon';
import { InputText } from '../InputText/InputText';
import styles from './InputSearch.module.scss';
import type { InputSearchProps } from './InputSearch.props';

export const InputSearch = ({ className, children, ...props }: InputSearchProps) => {
  const classNames = cn(styles.InputSearch, className);

  return (
    <div className={classNames}>
      <Icon icon={faSearch} className={styles.Icon} />

      <InputText {...props} className={styles.InputText} />
    </div>
  );
};
