import type { ControllerFieldState } from 'react-hook-form';

export const getFieldStateValidation = (fieldState: ControllerFieldState) => {
  const isError = !!fieldState.error === true;
  const isValid = !!fieldState.error === false && fieldState.isTouched;

  return {
    isValid,
    isError
  };
};
export const isFile = (input: unknown): input is File => {
  return 'File' in window && input instanceof File;
};

export const isFileList = (input: unknown): input is Array<File> => {
  return Array.isArray(input) && input.every(isFile);
};
