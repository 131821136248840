import { acousticClient } from '@Services/clients';
import { ResourceService } from '@Services/common';

import type {
  IProject,
  ProjectGetListForCustomerRequest,
  ProjectGetListForCustomerResponse
} from './ProjectService.props';

export class ProjectService extends ResourceService<IProject> {
  getListForCustomer = async ({ customerId, ...config }: ProjectGetListForCustomerRequest) => {
    const response = await this.get<ProjectGetListForCustomerResponse>(
      `/customer/${customerId}`,
      config
    );

    return response.data;
  };
}

export const projectService = new ProjectService(acousticClient.getAxiosInstance(), '/projects');
