import cn from 'classnames';

import { Row } from './internal';
import styles from './Table.module.scss';
import type { TableProps } from './Table.props';

export const Table = <T,>({ className, children, data, columns, ...props }: TableProps<T>) => {
  const classNames = cn(styles.Table, className);

  return (
    <table className={classNames} {...props}>
      <thead>
        <tr>{children}</tr>
      </thead>

      <tbody>
        {data.map((row, idx) => {
          return <Row key={(row as any)?.id || idx} row={row} columns={columns as string[]} />;
        })}
      </tbody>
    </table>
  );
};

export { createTable } from './internal';
