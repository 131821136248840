import cn from 'classnames';

import themes from '@Constants/themes';
import { scopedCssVars } from '@Utils/ThemeUtils';

import styles from './Spinner.module.scss';
import type { SpinnerProps } from './Spinner.props';

export const Spinner = ({ color = 'primary', size = 40, className, ...props }: SpinnerProps) => {
  const classNames = cn(styles.Spinner, className);
  const loaderVars = scopedCssVars({
    size: `${size}px`,
    color: themes.default.color[color].default,
    colorLight: themes.default.color[color].light
  });
  const loaderClassNames = cn(styles.Loader, loaderVars);

  return (
    <div className={classNames} {...props}>
      <div className={loaderClassNames} />
    </div>
  );
};
