import { authService } from '@Services/AuthService';
import { ApiClient } from '@Services/common';
import { getAccessTokens } from '@Utils/StorageUtils';

const getAccessToken = () => {
  const tokens = getAccessTokens();

  return tokens?.accessToken;
};

export const acousticClient = new ApiClient(
  process.env.REACT_APP_API_URL as string,
  authService.logout,
  authService.refreshTokens,
  getAccessToken
);
