import type { NavigateProps } from 'react-router-dom';
import { Navigate, useParams } from 'react-router-dom';

import type { HintedString } from '@Constants/types';
import type locales from '@Locales/index';
import { AppRoutes } from '@Navigation/Routes';

import { i18n } from './TranslationUtils';

export type TranslatedSlugs = keyof typeof locales.nl.translation.COMMON.NAVIGATION;

export type NavSlugs = HintedString<TranslatedSlugs>;

export const translateSlug = (slug: NavSlugs) => i18n.t(`COMMON.NAVIGATION.${slug}.SLUG`, slug);

export const getLink = (...params: (NavSlugs | number | undefined)[]) => {
  const urlParts = params.flatMap((urlPart) => String(urlPart ?? '').split('/'));
  const translatedUrlParts = urlParts.map(translateSlug).filter(Boolean);
  return `/${translatedUrlParts.join('/')}`;
};

export const getLinkLabel = (slug: TranslatedSlugs) => i18n.t(`COMMON.NAVIGATION.${slug}.LABEL`);

export interface RedirectProps extends Omit<NavigateProps, 'to'> {
  home: Lowercase<keyof typeof HomeRoutes>;
}

export enum HomeRoutes {
  ADVISOR = 'ADVISOR',
  CUSTOMER = 'CUSTOMER',
  LOGIN = 'LOGIN'
}

export const Redirect = ({ home, ...props }: RedirectProps) => {
  const { customerId } = useParams();

  const HomeRouteMethods = {
    advisor: AppRoutes.getAdvisorHome().to,
    customer: AppRoutes.getCustomerHome(customerId).to,
    login: AppRoutes.getLogin().to
  };

  const to = HomeRouteMethods[home];

  return <Navigate to={to} {...props} />;
};
