import { Route, Routes } from 'react-router-dom';

import { CustomerPage } from '@Components/layout';
import {
  AuthForgotPasswordScreen,
  AuthLoginScreen,
  AuthUpdatePasswordComplete,
  AuthUpdatePasswordScreen
} from '@Screens/Auth';
import {
  CustomerAccountScreen,
  CustomerHomeScreen,
  CustomerProductDetailScreen,
  CustomerProductScreen,
  CustomerProjectDetailScreen,
  CustomerProjectsScreen
} from '@Screens/Customer';
import { Redirect } from '@Utils/RouteUtils';

import { AppRoutes } from './Routes';

export const PublicStack = () => {
  return (
    <Routes>
      <Route path={AppRoutes.getLogin().path} element={<AuthLoginScreen />} />

      <Route path={AppRoutes.getForgotPassword().path} element={<AuthForgotPasswordScreen />} />

      <Route path={AppRoutes.getUpdatePassword().path} element={<AuthUpdatePasswordScreen />} />

      <Route
        path={AppRoutes.getUpdatePasswordComplete().path}
        element={<AuthUpdatePasswordComplete />}
      />

      <Route path={AppRoutes.getCustomerHome().path} element={<CustomerPage />}>
        <Route index element={<CustomerHomeScreen />} />

        <Route path={AppRoutes.getCustomerAccount().path} element={<CustomerAccountScreen />} />

        <Route path={AppRoutes.getCustomerProjects().path}>
          <Route index element={<CustomerProjectsScreen />} />

          <Route
            path={AppRoutes.getCustomerProjectDetail().path}
            element={<CustomerProjectDetailScreen />}
          />
        </Route>

        <Route path={AppRoutes.getCustomerProducts().path} element={<CustomerProductScreen />}>
          <Route
            path={AppRoutes.getCustomerProductDetail().path}
            element={<CustomerProductDetailScreen />}
          />
        </Route>

        <Route path="*" element={<Redirect home="customer" />} />
      </Route>

      <Route path="*" element={<Redirect home="login" />} />
    </Routes>
  );
};
