import type { IBaseModel, ListRequest, ListResponse, OneRequest } from '@Constants/interfaces';
import { BaseService } from '@Services/common';

export class ResourceService<T extends IBaseModel> extends BaseService {
  getList = async (params: ListRequest = {}) => {
    const { data } = await this.get<ListResponse<T>>('/', params);

    return data;
  };

  getOne = async ({ id }: OneRequest) => {
    const { data } = await this.get<T>(`/${id}`);

    return data;
  };
}
