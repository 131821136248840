import { useState } from 'react';

import { createTable } from '@Components/ui';
import { SortDirection } from '@Constants/enums';

export const useTable = <T>() => {
  const [sortBy, setSortBy] = useState<any>('');
  const [sortDirection, setSortDirection] = useState<any>(SortDirection.DESC);
  const context = { sortBy, setSortBy, sortDirection, setSortDirection };
  const Table = createTable<T>(context);

  return { Table, ...context };
};
