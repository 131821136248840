import cn from 'classnames';

import { SortDirection } from '@Constants/enums';

import { SortButton } from '../..';
import styles from './Column.module.scss';
import type { ColumnProps } from './Column.props';

export const Column = <T,>({
  className,
  children,
  source,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  ...props
}: ColumnProps<T>) => {
  const handleClick = () => {
    if (sortBy === source) {
      if (sortDirection === SortDirection.DESC) {
        setSortDirection(SortDirection.ASC);
      } else {
        setSortBy('');
        setSortDirection(SortDirection.DESC);
      }
    } else {
      setSortBy(source);
      setSortDirection(SortDirection.DESC);
    }
  };

  const direction = sortBy === source ? sortDirection : '';

  const classNames = cn(styles.Column, className, {
    [styles.isActive]: !!direction
  });

  return (
    <th className={classNames} {...props}>
      <button onClick={handleClick} className={styles.Button}>
        {children}

        <SortButton direction={direction ?? ''} />
      </button>
    </th>
  );
};
