import { AdvisorCustomers } from '@Components/network';

import styles from './AdvisorHomeScreen.module.scss';

export const AdvisorHomeScreen = () => {
  return (
    <div className={styles.AdvisorHomeScreen}>
      <AdvisorCustomers />
    </div>
  );
};
