export enum StorageKey {
  ACCESS_TOKEN = 'REACT_APP_ACCESS_TOKEN'
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum ApiErrorCode {}

export enum AppError {
  NO_CUSTOMER_ID_PARAM = 'There is no customer ID present in the URL.',
  NO_AUTH_CONTEXT_SET = 'Set a context provider first.',
  NO_ACCESS_TOKENS_FOUND = 'No access tokens were found in localStorage.'
}
