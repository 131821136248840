import { Link, Outlet } from 'react-router-dom';

import { AppRoutes } from '@Navigation/Routes';

export const AdvisorProductsScreen = () => {
  return (
    <>
      <div>AdvisorProductScreen</div>

      <Link to={AppRoutes.getAdvisorProductDetail('7').to}>
        {`
Go to single product
`}
      </Link>

      <Outlet />
    </>
  );
};
