import axios from 'axios';

import { AppError } from '@Constants/enums';
import { BaseService } from '@Services/common/BaseService/BaseService';
import {
  getAccessTokens,
  getIsRefreshStillValid,
  removeAccessTokens,
  setAccessTokens
} from '@Utils/StorageUtils';
import { i18n } from '@Utils/TranslationUtils';

import type {
  AuthLoginRequest,
  AuthLoginResponse,
  AuthRefreshTokenResponse,
  AuthUserResponse,
  IUser
} from './AuthService.props';

export class AuthService extends BaseService {
  login = async (params: AuthLoginRequest) => {
    const { data } = await this.post<AuthLoginResponse>(`/login`, params);

    setAccessTokens(data);

    return data;
  };

  logout = async () => {
    await this.post(`/logout`);

    removeAccessTokens();
  };

  getMe = async (): Promise<AuthUserResponse> => {
    if (!getIsRefreshStillValid()) {
      return false;
    }

    const { data } = await this.getAxiosInstance().get<IUser>('/me');

    return data;
  };

  refreshTokens = async () => {
    const tokens = getAccessTokens();

    if (!tokens) {
      throw new Error(i18n.t(AppError.NO_ACCESS_TOKENS_FOUND));
    }

    const params = {
      refreshToken: tokens.refreshToken
    };

    const { data } = await this.post<AuthRefreshTokenResponse>(`/refresh`, params);

    setAccessTokens(data);
  };
}

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

authInstance.interceptors.request.use(({ headers, ...config }) => {
  if (!headers) {
    return config;
  }

  const accessToken = getAccessTokens()?.accessToken;
  headers.Authorization ??= accessToken ? `Bearer ${accessToken}` : '';

  return { headers, ...config };
});

export const authService = new AuthService(authInstance, '/auth');
