import cn from 'classnames';
import { cloneElement, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { InputField } from '@Components/ui';
import { Text } from '@Components/ui/Text/Text';
import { getFieldStateValidation } from '@Utils/FormUtils';

import styles from './FormField.module.scss';
import type { FormFieldProps } from './FormField.props';

export const FormField = ({
  className,
  children,
  label,
  description,
  shouldHideError,
  name,
  onChange,
  hasController,
  'data-testid': dataTestId
}: FormFieldProps) => {
  const { register, watch } = useFormContext();

  const { fieldState } = useController({
    name: name
  });

  const { isValid, isError } = getFieldStateValidation(fieldState);

  const value = onChange ? watch(name) : null;

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  return (
    <InputField
      name={name}
      label={label}
      description={description}
      className={cn([styles.FormField, className])}
      data-testid={dataTestId}
    >
      <>
        {hasController
          ? children
          : cloneElement(children, {
              isError,
              isValid,
              id: name,
              ...register(name)
            })}

        {isError && fieldState.error && !shouldHideError && (
          <Text.Error className={styles.Error}>{fieldState.error.message}</Text.Error>
        )}
      </>
    </InputField>
  );
};
